.how-it-works-page {
  background: url("../../assets/images/howitworks/how-it-work-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;
}

.how-it-works-page .title-page h2:after {
  left: 300px;
  width: calc(100% - 300px);
}

.left-content p {
  font-size: 12px;
  color: #fff;
}

.right-content h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 35px;
  position: relative;
  padding-bottom: 30px;
  position: relative;
  top: -20px;
}

.info-box {
  display: flex;
  align-items: flex-start;
  padding-bottom: 25px;
}

.info-list h4 {
  color: #fff;
  font-family: "Molot";
  font-size: 22px;
  position: relative;
}

.info-box img {
  width: 100px;
}

.info-list p {
  font-size: 12px;
  color: #fff;
}

.info-list {
  padding-left: 20px;
}

.border-line .right-content {
  border-left: 1px solid #fff;
}

.right-content {
  padding-left: 50px;
  padding-bottom: 70px;
  position: relative;
}

.right-content h2:before {
  content: "";
  width: 40px;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  left: -50px;
  top: 20px;
}

.right-content .btn {
  margin-left: 120px;
}
/* .span-content{
    font-size: 15px;
    font-weight: 500;
} */
.search-tournament {
  color: white !important;
}

.duel-description {
  color: #fff;
  position: relative;
  top: -35px;
  font-size: 14px;
}
.left-content ul li {
  font-size: 12px;
  color: #fff;
}
.left-content .textright-heading {
  color: white;
  /* text-align: right; */
  color: #fff;
  font-family: "Molot";
  font-size: 22px;
  position: relative;
}
.left-content .textright {
  /* text-align: right; */
}
.info-box-new {
  margin-top: 200px;
  /* text-align: right; */
}
.info-box-new1 {
  margin-top: 183px;
}
