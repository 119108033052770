.choose-friend-content h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 35px;
  position: relative;
  text-align: center;
  margin: 0;
  padding-bottom: 50px;
}

.choose-friend-content {
  max-width: 500px;
  margin: 0px auto;
}

.choose-friend-content form {
  max-width: 300px;
  margin: 0px auto;
}

.choose-friend-content .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
}

.choose-friend-content .dropdown-toggle:focus {
  background: transparent !important;
  border: 1px solid #fff !important;
  outline: none !important;
  box-shadow: none;
}

.choose-friend-content .form-label {
  color: #fff;
}

.choose-button .btn {
  padding: 10px 20px;
}

.choose-button {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.choose-friend-content .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}

.choose-friend-content .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #fff;
}

.choose-friend-content .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative !important;
  transform: inherit !important;
  margin-bottom: 20px;
}

.choose-friend-content .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}

.choose-friend-content .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}

.choose-friend-content .dropdown-item img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
}

.choose-friend-content .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}

.choose-friend-content .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}

.choose-friend-content .show .dropdown-toggle::after {
  transform: rotate(180deg);
}

.choose-game-content h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 35px;
  position: relative;
  text-align: center;
  margin: 0;
  padding-bottom: 50px;
}

.choose-game-content {
  max-width: 500px;
  margin: 0px auto;
}

.choose-game-content form {
  margin: 0px auto;
}

.max-300 {
  max-width: 300px;
  margin: 0px auto;
  margin-bottom: 30px;
}

.choose-game-content .choose-button {
  max-width: 300px;
  margin: 0px auto;
}

.choose-game-content .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
}

.choose-game-content .dropdown-toggle:focus {
  background: transparent !important;
  border: 1px solid #fff !important;
  outline: none !important;
  box-shadow: none;
}

.choose-game-content .form-label {
  color: #fff;
  display: block;
}

.choose-game-content .form-label.text-center {
  text-align: center;
}

.choose-game-content .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}

.clear {
  clear: both;
}
.entry-fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.entry-box {
  width: 25%;
  text-align: center;
  padding: 10px 0px;
}

.entry-box img {
  margin: 0px auto;
  display: block;
  border: 1px solid #fff;
  padding: 7px 10px;
  border-radius: 3px;
}

.entry-box span {
  color: #fff;
  font-size: 12px;
}

.choose-game-content .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #fff;
}

.choose-game-content .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}

.choose-game-content .show .dropdown-toggle::after {
  transform: rotate(180deg);
}

.choose-game-content .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative !important;
  transform: inherit !important;
  margin-bottom: 20px;
}

.choose-game-content .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}

.choose-game-content .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}

.choose-game-content .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}

/************ Invite Friends ***********/

.invite-friend-content h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 35px;
  position: relative;
  text-align: center;
  margin: 0;
  padding-bottom: 50px;
}

.invite-friend-content {
  max-width: 350px;
  margin: 0px auto;
}

.invite-friend-content form {
  margin: 0px auto;
}

.invite-friend .max-300 {
  max-width: 300px;
  margin: 0px auto;
  margin-bottom: 30px;
  position: relative;
}

.invite-friend .max-300 button {
  color: #fff;
  position: absolute;
  right: 12px;
  bottom: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 99;
}

.invite-friend .entry-box .btn {
  font-size: 20px;
  padding: 8px 40px;
  width: 100%;
}

.invite-friend-content .choose-button {
  max-width: 300px;
  margin: 0px auto;
}

.invite-friend-content .form-control {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
  padding-right: 50px;
  color: #fff;
}

.invite-friend-content .form-control::placeholder {
  color: #fff;
}

.invite-friend-content .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
}

.invite-friend-content .dropdown-toggle:focus {
  background: transparent !important;
  border: 1px solid #fff !important;
  outline: none !important;
  box-shadow: none;
}

.invite-friend-content .form-label {
  color: #fff;
  display: block;
}

.invite-friend-content .form-label.text-center {
  text-align: center;
}

.invite-friend-content .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}

.invite-friend .entry-fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.invite-friend .entry-box {
  width: 33.33%;
  text-align: center;
  padding: 10px 0px;
}

.invite-friend .entry-box img {
  margin: 0px auto;
  display: block;
  border: 1px solid #fff;
  padding: 7px 10px;
  border-radius: 3px;
}

.invite-friend .entry-box span {
  color: #fff;
  font-size: 12px;
}

.invite-friend-content .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #fff;
}

.invite-friend-content .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}

.invite-friend-content .show .dropdown-toggle::after {
  transform: rotate(180deg);
}

.invite-friend-content .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative !important;
  transform: inherit !important;
  margin-bottom: 20px;
}

.invite-friend-content .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}

.invite-friend-content .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}

.invite-friend-content .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}
