.login-section {
  padding: 75px 0px;
  /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

/* .login-section:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} */

.login-form {
  max-width: 700px;
  margin: 0px auto;
}

.login-form h1 {
  font-size: 35px;
  text-align: center;
  font-family: "Molot";
  color: #fff;
}

.login-box label {
  color: #fff;
  font-family: "Roboto-Bold" !important;
}

.login-box .form-group .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #999;
}

.login-box .form-group .form-control::placeholder {
  color: #999;
}

.login-button {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.loginBtn {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.forget-password {
  text-align: center;
}

.forget-password {
  color: #bbb;
  padding: 10px 0px;
  font-size: 13px;
}

.login-box {
  font-family: "Roboto-Light" !important;
}

.login-box p {
  text-align: center;
  font-size: 13px;
  color: #bbb;
  padding-top: 5px;
}

.forget-link,
.signup-link {
  color: #bbb;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
}

.forget-link span,
.signup-link span {
  color: #3bbdf5;
}

.forget-link:hover,
.signup-link:hover {
  color: #fff;
}

/******** Social Login ***********/

.social-login {
  text-align: center;
  padding: 30px 0px;
  padding-bottom: 70px;
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 14px 50px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 13px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn {
  box-sizing: border-box;
  position: inline;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: inline;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: none !important;
  background: url("../../assets/images/header/facebook-icon.png") 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  float: none;
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: none;
  background: url("../../assets/images/header/google-icon.png") no-repeat;
  width: 21px;
  height: 22px;
  background-size: cover;
  position: absolute;
  left: 10px;
  top: 5px;
  padding-right: 5px;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

.loginBtn:before,
.loginBtn:after {
  display: none;
}
