.game-player-result tr td {
  text-align: center !important;
}

.game-player-result tr th {
  text-align: center !important;
}

.player-page {
  padding-top: 182px !important;
  padding-bottom: 50px !important;
}
.player-page select.form-control option {
  background: #050c1b !important;
  position: absolute !important;
  transform: inherit !important;
  height: 96px;
  top: 49px !important;
  overflow-y: hidden;
  overflow-x: hidden;
}
.player-page select.form-control option:hover {
  background-color: #091e3ed1 !important;
  color: #fff;
}
.player-page select.form-control option:active {
  background: #e90707d1 !important;
  color: #fff;
}
.player-page .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}

.player-page .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3bbdf5;
  font-family: "Molot";
}
.player-page a {
  color: #fff;
  text-decoration: none;
  font-family: "Molot";
  margin-right: 30px;
}

/******************* Player List************* */
.player-list .home-table .table > thead > tr,
.player-list .home-table .table > tbody > tr {
  border: 1px solid#3d4253;
  display: grid;
  grid-template-columns: calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) !important;
  position: relative;
  align-items: center;
}
.player-list .home-table .table > thead > tr {
  border: none;
}
.player-list .home-table .table-bordered > thead > tr > th {
  border: 0;
  font-style: normal;
  text-transform: none;
  font-size: 12px;
  font-family: "Roboto-Bold" !important;
  letter-spacing: 0.5px;
}
.player-list .home-table .table > tbody > tr > td {
  padding: 15px 20px;
}
.player-list .home-table .table > tbody > tr > td img {
  width: 88px;
  height: 88px;
  object-fit: cover;
}
.player-list.home-table .table-bordered > thead > tr > th {
  border: 0;
  font-style: normal;
  text-transform: none;
  font-size: 12px;
  font-family: "Roboto-Bold" !important;
  letter-spacing: 0.5px;
}
/********** No data td css **************/
/* .player-list .home-table .table>tbody>tr>td.floting {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
} */
/* .player-list .home-table .table>tbody>tr>td {
        height: 50px;
} */
.my-input .form-control {
  border-image-slice: 1 !important;
  border-width: 1px !important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
  background: transparent;
}
.player-list .dropdown.nickname-list {
  width: 100%;
}
