.transactionpage {
  background: url("../../assets/images/home/faq-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 150px !important;
  padding-bottom: 50px !important;
}

.transactionpage .title-page h2:after {
  left: 300px;
  width: calc(100% - 300px);
}

.transactionpage h3 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
  position: relative;
  margin-top: 50px;
}

.transactionpage .table > thead > tr > th {
  padding: 10px 20px;
}

.transactionpage .home-table .table > tbody > tr > td {
  padding: 15px 20px;
  white-space: nowrap;
}

/* 
.transactionpage .table > thead > tr > th:nth-child(2) {
  width: 35%;
} */

.transactionpage .title-page {
  position: relative;
  padding-bottom: 50px;
}

.transactionpage .home-table .table > tbody > tr > td.winner {
  font-size: 20px;
  font-family: "Molot" !important;
  text-shadow: 0px 0px 10px #fff;
}

.transactionpage .title-page .red-btn {
  position: absolute;
  right: 0;
  top: 50%;
}

.transactionpage .home-table .table > tbody > tr > td .btn {
  padding: 0;
  color: #fff;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  text-transform: capitalize;
}

.transactionpage .home-table .table > tbody > tr > td .btn span {
  color: #3bbdf5;
}
.previous,
.next {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 5px 20px;
  margin: 5px;
}
.pagination-section {
  display: flex;
  justify-content: flex-end;
}

.transactionpage .home-table .table > tbody > tr,
.transactionpage .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) !important;
}
/* tr.red{
  
  border: 1px solid red!important;
}
tr.red td{
  color: red!important;
}
tr.green td{
  color: green!important;
}
tr.green{
  color: green;
  border: 1px solid green!important;
} */
tr.red .amount::after {
  content: "";
  background: url("../../assets/images/home/flare_blue.png");
  height: 22px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  min-width: 150px;
  margin-left: -47px;
}
.amount::after {
  content: "";
  background: url("../../assets/images/home/flare_red.png");
  height: 22px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  min-width: 150px;
  margin-left: -47px;
}
