body {
  margin: 0;
  padding: 0;
  color: #1c1c1c !important;
  font-family: "Roboto-Regular" !important;
  background: #0d111e !important;
  /* cursor: url('./assets/images/header/Kek-32.gif'), auto; */
}

@font-face {
  font-family: "OhNow";
  src: url("./assets/fonts/OhNow.otf");
}

@font-face {
  font-family: "Molot";
  src: url("./assets/fonts/Molot.otf");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    justify-content: space-between !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px !important;
  }

  /* .home-banner .container {
    max-width: 1300px!important;
} */

  .faqs .container {
    max-width: 1100px !important;
  }

  .features-section .container {
    max-width: 1100px !important;
  }

  .legal .container {
    max-width: 1100px !important;
  }

  .tournament-page .container {
    max-width: 1100px !important;
  }

  .notificationpage .container {
    max-width: 1100px !important;
  }

  .profile .container {
    max-width: 1100px !important;
  }

  .how-it-works-page .container {
    max-width: 1100px !important;
  }

  .default-section .container {
    max-width: 1100px !important;
  }

  .aboutus .container {
    max-width: 1100px !important;
  }

  .transactionpage .container {
    max-width: 1100px !important;
  }
}

.main_content .react-p5 {
  text-align: center;
}

.ponggame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ponggame:focus {
  outline: none;
}
.playerScore {
  display: flex;
  max-width: 900px;
  margin: 0px auto;
  justify-content: space-between;
}

.playerScore div {
  width: 50%;
  text-align: center;
  border: 1px solid;
}
.playerScore div h4 {
  color: indianred;
}

.ponggame button {
  position: absolute;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 4px solid #8e1818;
  outline: none !important;
}

.scrollhide {
  overflow: hidden !important;
}

/************ Home New Design *****************/

a:focus {
  color: #ffffff !important;
}

/* .home {
  background: url("./assets/images/home/home-bg.jpg");


  background-size: cover;
  background-repeat: no-repeat;
} */
/* .home .home-banner:before {
  content: "";
  background:#030818c9;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
} */

.home .home-banner {
  position: relative;
}

header {
  z-index: 99;
}

.home {
  background-color: #0d111e;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

/* .home .home-banner{
  background: url("./assets/images/home/1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
   min-height: 100vh; 
  display: flex;
  align-items: center;
  background-position: top center;
  
} */
.home .home-banner {
  background: url("./assets/images/home/1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  background-position: top center;
  height: 710px;
}

.home .gamepad-bg {
  background: url("./assets/images/home/2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
}

.home .how-tournament-section {
  /* background: url("./assets/images/home/41.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: auto;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
}

/* .how-tournament-section::before {
  content: "";
  background: #05081b26;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
} */

.align-tournament {
  width: 100%;
}

/* .home .day-of-duel-section{
  background: url("./assets/images/home/55.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;

} */
.home .day-of-duel-section {
  background: url("./assets/images/home/55.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 609px;
  background-position: center;
  display: flex;
  align-items: center;
}

/* .home-banner {
  padding: 150px 0px;
  padding-bottom: 100px;

} */
.home-banner {
  padding: 0px 0px;
  padding-bottom: 0px;
}

.home-text h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 50px;
  margin: 0;
}

.home-text h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 38px;
  margin: 0;
  max-width: 600px;
}

.home-text {
  font-family: "Molot";
  padding-top: 50px;
}

.home-btns {
  padding-top: 40px;
}

.home-btns .btn {
  margin-right: 10px;
  padding: 12px 35px;
}

.home-btns h1 {
  font-size: 24px;
  color: #ff0031;
  padding-bottom: 20px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #3bbdf5 !important;
}

.red-btn {
  background: #e00033 !important;
  border-color: #e00033 !important;
}

.blue-btn {
  background: #3bbdf5 !important;
  border-color: #3bbdf5 !important;
}

.red-btn.btn-primary:hover,
.red-btn.btn-primary:focus {
  background: #e00033 !important;
  border-color: #e00033 !important;
  box-shadow: 0px 0px 50px 0px #ffffff9c;
  outline: none;
}

.red-btn.btn-primary:focus,
.red-btn.btn-primary.focus {
  background-color: #e00033 !important;
  border: 1px solid #e00033 !important;
  box-shadow: none;
}

.blue-btn.btn-primary:hover,
.blue-btn.btn-primary:focus {
  background: #3bbdf5 !important;
  border-color: #3bbdf5 !important;
  box-shadow: 0px 0px 50px 0px #ffffff9c;
  outline: none;
}

.blue-btn.btn-primary:focus,
.blue-btn.btn-primary.focus {
  background-color: #3bbdf5 !important;
  border: 1px solid #3bbdf5 !important;
  box-shadow: none;
}

.border-btn {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff;
}

.border-btn:hover,
.border-btn:focus {
  background: transparent !important;
  border-color: #fff !important;
  box-shadow: 0px 0px 50px 0px #ffffff9c;
  outline: none;
}

.home-table .table-bordered {
  border: none;
  border-spacing: 0px 10px;
  border-collapse: separate;
}

.home-table .table-bordered > thead > tr > th {
  border: 0;
  font-style: normal;
  text-transform: none;
  font-size: 12px;
  font-family: "Roboto-Bold" !important;
  letter-spacing: 0.5px;
}

.home-table .table > tbody > tr > td {
  border: none;
  /* border-top: 1px solid #fff;
  border-bottom: 1px solid #fff; */
  color: #fff;
  font-size: 12px;
  font-family: "Roboto-Medium" !important;
  letter-spacing: 0.5px;
  vertical-align: middle;
  padding: 3px 10px;
}

.home-table .table > tbody > tr {
  border: 1px solid #fff;
}

.home-table .table > tbody > tr > td i {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}

.home-table .table > tbody > tr > td.prize {
  font-size: 16px;
}

/* .home-table .table > tbody > tr > td:first-child {
  border-left: 1px solid #fff;
}

.home-table .table > tbody > tr > td:last-child {
  border-right: 1px solid #fff;
} */

/* .home-table .table > tbody > tr > td i:hover {
  box-shadow: 0px 0px 10px 0px #ffffff9c;
} */

/******** Game Table *********/

.home .home-table .table-bordered > thead > tr > th {
  text-align: center;
}

.home .home-table .table-bordered > tbody > tr > td {
  text-align: center;
}

.home .home-table .table-bordered > thead > tr > th:first-child {
  text-align: left;
}

.home .home-table .table-bordered > tbody > tr > td:first-child {
  text-align: left;
}

.home .home-table .table-bordered > thead > tr > th:nth-child(2) {
  width: 40%;
}
.table-game a::before {
  display: none !important;
}

.home-game-table .game-list-view:nth-child(2) .table-game a::before {
  display: block !important;
}

.home-game-table .game-list-view:nth-child(3) .table-game a::before {
  display: block !important;
}

.title-page {
  position: relative;
  padding-bottom: 50px;
}

.title-page h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 40px;
  position: relative;
  margin: 0;
}

/* .title-page h2:after {content: "";background: #fff;height: 2px;display: block;position: absolute;top: 50%;z-index: 9;left: 150px;width: calc(100% - 150px);transform: translateY(-50%);} */

.title-page h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.6em;
  border-top: 2px solid white;
  z-index: 9;
}

.title-page h2 span {
  background: #030617;
  position: relative;
  z-index: 99;
  padding-right: 13px;
  background: rgb(0, 7, 20);
  background: linear-gradient(-90deg, rgb(3 10 36) 0%, rgb(3 6 23) 69%);
}

ul.breadcrum {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  font-family: "Roboto-Light" !important;
  padding-bottom: 30px;
  padding-top: 10px;
}

ul.breadcrum li {
  padding-right: 15px;
  margin-right: 8px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  font-family: "Roboto-Medium";
}

ul.breadcrum li a {
  color: #bdbdbd;
  font-family: "Roboto-Regular";
}

ul.breadcrum li a:hover {
  text-decoration: underline;
  text-decoration-color: #3bbdf5;
}

ul.breadcrum li:first-child:after {
  content: ">";
  position: absolute;
  right: 0;
  color: #3bbdf5;
}

.wallet-container h4 {
  color: #fff;
  font-size: 13px;
  margin: 0;
  font-family: "Roboto-Bold";
  margin-right: 10px;
}

.wallet-container .btn {
  padding: 0;
  border: none;
  background: transparent;
  padding-right: 15px;
}

.wallet-container .dropdown-menu {
  background: transparent;
}

.wallet-container .dropdown-menu .dropdown-item:hover {
  background: transparent;
}

.wallet-box h5 {
  text-transform: capitalize;
}

.wallet-container .btn:focus,
.wallet-container .btn:hover {
  background: transparent;
  outline: none;
  box-shadow: none;
}

.wallet-container .btn:after {
  display: none;
}
.wallet-container {
  margin-left: 40px;
  align-items: center;
}

.wallet-container .btn-default:active:hover {
  background: transparent;
  outline: none;
}

.wallet-container .dropdown-item:focus {
  background: transparent;
}

.wallet-container .btn::after {
  background: url(./assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 12px;
  height: 6px;
  background-size: cover;
  position: absolute;
  right: 7px;
  top: 10px;
}

.dropdown-menu .wallet-box {
  border: none;
}

.wallet-box {
  border-bottom: 1px solid #019acc;
}

.p-name .dropdown-toggle::after {
  display: none;
}

.p-name .dropdown-toggle::after {
  background: url(./assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 12px;
  height: 6px;
  background-size: cover;
  position: absolute;
  right: 2px;
  top: 9px;
}

.language-dropdown {
  padding: 0px 10px;
}

.language-dropdown .dropdown-toggle {
  background: transparent;
  color: #fff;
  border: none;
  padding: 5px !important;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
}

.language-dropdown .dropdown-toggle:hover,
.language-dropdown .dropdown-toggle:focus {
  background: transparent;
  color: #fff;
  border: none;
  padding: 5px;
  font-size: 12px;
  outline: none;
  box-shadow: none;
}

.language-dropdown .btn-default:active:focus {
  background: transparent;
}

.language-dropdown .btn-default:active:hover {
  background: transparent;
}

.language-dropdown .dropdown-toggle::after {
  display: none;
}

.language-dropdown .dropdown-toggle::after {
  background: url(./assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 12px;
  height: 6px;
  background-size: cover;
  position: absolute;
  right: -10px;
  top: 10px;
}

/*********** Popular Games ***********/

/* .popular-game-section {
  padding-bottom: 100px;
  padding-top: 0px;
} */
.popular-game-section {
  padding-bottom: 0px;
  padding-top: 0px;
}
.align-tournament {
  margin-top: 100px;
}

.popular-game-section .title h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 40px;
}

.popular-box {
  position: relative;
  border: 1px solid #3d4253;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 305px;
}

.popular-box::after {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.popular-box::before {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.many-players {
  background: #000000b5;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 8px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
}

.many-players img {
  width: 25px;
  vertical-align: top;
  margin-right: 5px;
}

.game-info h4 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
}

.game-info p {
  color: #fff;
  font-size: 13px;
  margin: 0;
  letter-spacing: 0.2px;
  font-family: "Roboto-Light" !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* .game-action {
  position: absolute;
  top: 10px;
  right: 10px;
} */

.game-info {
  padding: 20px 15px;
}

.game-action .duel-action {
  position: absolute;
  top: 12px;
  right: 10px;
}
.game-action.winter-game .duel-action {
  position: absolute;
  top: 7px;
  right: 10px;
}

.game-action.winter-game .tournament-action {
  position: absolute;
  top: 47px;
  right: 10px;
}
.game-action .tournament-action {
  position: absolute;
  top: 65px;
  right: 10px;
}
.game-action.winter-game .newtournament-action {
  position: absolute;
  top: 129px;
  right: 10px;
}
.game-action .newtournament-action {
  position: absolute;
  top: 120px;
  right: 10px;
}
.game-action.winter-game .duel-action,
.game-action.winter-game .tournament-action,
.game-action.winter-game .newtournament-action,
.game-action.winter-game .multiplayer-action {
  color: #fff;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: auto;
  justify-content: center;
}
.game-action .duel-action,
.game-action .tournament-action,
.game-action .newtournament-action,
.game-action .multiplayer-action {
  color: #fff;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.game-action .multiplayer-action {
  position: absolute;
  top: 87px;
  right: 10px;
}
.multiplayer-action {
  background: #12598e;
  border: 1px solid #12598e;
}
.game-action.mobile-app-tile .duel-action,
.game-action.mobile-app-tile .tournament-action,
.game-action.mobile-app-tile .newtournament-action {
  overflow: inherit;
}

.game-action .duel-action img,
.game-action .tournament-action img,
.game-action .newtournament-action img,
.game-action .multiplayer-action img {
  width: 28px;
}

.game-action .duel-action span,
.game-action .multiplayer-action span,
.game-action .tournament-action span,
.game-action .multiplayer-action span,
.game-action .newtournament-action span {
  opacity: 0;
  white-space: nowrap;
  transition: all 1s;
  padding-left: 5px;
  display: none;
}

.game-action .duel-action:hover,
.game-action .tournament-action:hover,
.game-action .newtournament-action:hover,
.game-action .multiplayer-action:hover {
  width: 170px;
}
.game-action.winter-game .duel-action:hover,
.game-action.winter-game .tournament-action:hover,
.game-action.winter-game .newtournament-action:hover,
.game-action.winter-game .multiplayer-action:hover {
  width: 170px !important;
}

.game-action .duel-action:hover span,
.game-action .tournament-action:hover span,
.game-action .multiplayer-action:hover span,
.game-action .newtournament-action:hover span {
  opacity: 1;
  display: inline-block;
}
.game-action.winter-game .duel-action:hover span,
.game-action.winter-game .tournament-action:hover span,
.game-action.winter-game .multiplayer-action:hover span,
.game-action.winter-game .newtournament-action:hover span {
  opacity: 1;
  display: inline-block;
}
.popular-box .game-img {
  position: relative;
}

.popular-box .game-img img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.popular-box .game-img .try img {
  width: 28px;
  height: auto;
}

.try {
  background: #000000b5;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  width: 50px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom-left-radius: 0;
}

.try span {
  opacity: 0;
  white-space: nowrap;
  transition: all 1s;
  padding-left: 5px;
}

.try:hover span {
  opacity: 1;
}

.try:hover {
  width: 120px;
}

.duel-action {
  background: #e00033;
}

.tournament-action {
  background: #3bbdf5;
}

.newtournament-action {
  background: #000000b5;
  border: 1px solid #fff;
}

.popular-box.soon .game-info {
  padding: 20px 15px;
}

/*********** How To Tournament ***********/

.how-tournament-section {
  padding-bottom: 100px;
  position: relative;
  padding-top: 100px;
}

.how-tournament-section .title h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 40px;
}

.how-tournament-box {
  position: relative;
  text-align: center;
}

.how-tournament-icon {
  background: #0000007d;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid #fff;
  max-width: 120px;
  margin: 0px auto;
  min-height: 100px;
  margin-bottom: 20px;
}

.how-tournament-section .title {
  text-align: center;
  display: block;
  padding-bottom: 50px;
}

.how-tournament-info h4 {
  color: #fff;
  font-family: "Molot";
  font-size: 22px;
}

.how-tournament-info p {
  color: #fff;
  font-size: 12px;
  max-width: 150px;
  margin: 0px auto;
  letter-spacing: 1px;
}

.how-btn {
  text-align: center;
  padding-top: 80px;
}

.how-btn .btn {
  margin: 5px 10px;
  letter-spacing: 1.5px;
}

.igtv {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*********** Day of Duel ***********/

.day-of-duel-section {
  padding-bottom: 0px;
  position: relative;
  padding-top: 0px;
}

.day-of-duel-section .title {
  text-align: center;
  display: block;
  padding-bottom: 0px;
}

.day-of-duel-section .title h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 40px;
}

.duel-box {
  padding: 0px 25px;
}

.duel-info {
  text-align: center;
}

.duel-info h4 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
  padding-bottom: 10px;
}

.duel-info p {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1.5px;
  max-width: 400px;
  margin: 0px auto;
}

.duel-social {
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.duel-social li {
  margin: 0px 15px;
  padding-top: 10px;
}

.duel-info a {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
}

.duel-info a:hover,
.duel-info a:focus {
  color: #fff;
}

.duel-info a span {
  color: #3bbdf5;
}

.duel-social li a {
  border: none;
  padding: 0;
}

.duel-info.joinus p {
  max-width: 225px;
}

.duel-info.joinus a {
  padding-top: 20px;
  display: inline-block;
}

.duel-info.feature a {
  float: right;
  padding-top: 20px;
  margin-right: 20px;
}

.feature:before {
  content: "";
  background: url("./assets/images/home/seprator.png");
  width: 1px;
  position: absolute;
  height: 381px;
  left: 0;
  top: 50%;
  background-size: cover;
  transform: translateY(-50%);
}

.feature:after {
  content: "";
  background: url("./assets/images/home/seprator.png");
  width: 1px;
  position: absolute;
  height: 381px;
  right: 0;
  top: 50%;
  background-size: cover;
  transform: translateY(-50%);
}

footer {
  position: relative;
}

.chat-icon {
  position: fixed;
  left: 20px;
  bottom: 20px;
  cursor: pointer;
}

.chat-icon img {
  transition: all 0.5s;
}

/* .chat-icon img:hover{
  box-shadow: 0px 0px 50px 0px #ffffff9c;

} */

/*********** Home Tile ***********/

/* .home-tile-section{
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
} */
/* .home-tile-section {
  position: relative;
  padding-bottom: 100px;
  padding-top: 0px;
  margin-top: -19px;
} */

.tile-1 img {
  /* background: url('./assets/images/home/tile-11.png'); */
  width: 100%;
  height: 310px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #929292;
  border-radius: 5px;
}
.home-slider-image.tile-1 {
  position: relative;
}

.tile-2 {
  background: url("./assets/images/home/tile-2.png");
  width: 49%;
  height: 150px;
  background-repeat: no-repeat;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #929292;
  border-radius: 5px;
}

.tile-3 {
  background: url("./assets/images/home/tile-1new.png");
  width: 49%;
  height: 150px;
  background-repeat: no-repeat;
  float: right;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #929292;
  border-radius: 5px;
}

.tile-4 {
  /* background: url('./assets/images/home/tile-4.png'); */
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  clear: both;
  position: relative;
  top: 5px;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 5px;
}
.tile-4 img {
  /* background: url('./assets/images/home/tile-4.png'); */
  width: 100%;
  height: 150px;
  border: 1px solid #929292;
  border-right: 2px solid #929292;
  border-radius: 5px;
}

.caution-age p {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-family: "Roboto-Light" !important;
}

.caution-age p span {
  width: 35px;
  height: 35px;
  display: inline-block;
  border: 1px solid #fff;
  line-height: 35px;
  border-radius: 25px;
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
}

.home-tile-box {
  position: relative;
}

.home-tile-info * {
  color: #fff;
}

.tile-1 .home-tile-info {
  position: absolute;
  top: 50px;
  right: 50px;
  text-align: center;
}

.home-tile-box .tile-1 span {
  font-family: "OhNow";
  font-size: 80px;
  line-height: 20px;
}

.home-tile-box .tile-1 h4 {
  font-family: "Molot";
  font-size: 50px;
  margin: 0;
  line-height: 20px;
}

.home-tile-box .tile-1 h2 {
  font-family: "Molot";
  font-size: 90px;
  margin: 0;
  line-height: 140px;
}

.home-tile-box.tile-2 span {
  font-family: "OhNow";
  font-size: 55px;
  line-height: 20px;
}

.home-tile-box.tile-2 h4 {
  font-family: "Molot";
  font-size: 32px;
  margin: 0;
  line-height: 0;
}

.tile-2 .home-tile-info {
  position: absolute;
  right: 20px;
  text-align: center;
  top: 50px;
}

.home-tile-box.tile-3 span {
  font-family: "OhNow";
  font-size: 50px;
  line-height: 20px;
}

.home-tile-box.tile-3 h4 {
  font-family: "Molot";
  font-size: 40px;
  margin: 0;
  line-height: 20px;
}

.tile-3 .home-tile-info {
  position: absolute;
  left: 35px;
  text-align: center;
  top: 20px;
}

.tile-4 .home-tile-info {
  position: absolute;
  right: 35px;
  text-align: center;
  bottom: 30px;
}

.home-tile-box.tile-4 span {
  font-family: "OhNow";
  font-size: 60px;
  line-height: 50px;
  display: block;
}

.home-btns .btn a {
  color: #fff;
}

.how-btn .btn a {
  color: #fff;
}

.links {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
}

.links span {
  color: #3bbdf5;
}

.navbar.navbar-light .navbar-toggler-icon {
  background-image: url("./assets/images/header/menu-icon.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar-light .navbar-toggler:focus {
  outline: none;
}

.rigister-tab .form-group {
  margin-bottom: 20px;
}

/************************* Popup ****************************/

.modal-backdrop {
  background-color: #050c1bf7 !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.dark-popup {
  background: #050c1b;
}

.dod-popup .modal-content {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  align-items: center;
}

.dod-popup .modal-header {
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  z-index: 999;
}

.dod-popup .modal-header .close {
  color: #087088;
  text-shadow: none;
  opacity: 1;
  font-weight: 100;
  padding: 0 20px;
}

.dod-popup .modal-header .close:focus {
  outline: none;
}

.dod-popup.modal-dialog {
  width: 100%;
  margin: 0px auto;
  max-width: 100%;
}

.home-page.modal-open {
  overflow-y: auto !important;
}

.chatbox-overlay.show {
  display: none;
}

.chatbox-overlay + .modal {
  top: inherit;
  bottom: 20px;
  max-width: 300px;
  height: auto;
}

.dod-popup .modal-content {
  min-height: 100vh;
}

.dod-popup.chatbox-popup .modal-content {
  min-height: auto;
}

/************************* Default Page ****************************/

.default-section {
  background: url("./assets/images/home/profile-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px !important;
}

/* .default-section .title-page h2:after {
  left: 200px;
  width: calc(100% - 200px);
} */

.default-section p {
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Roboto-Light" !important;
}

.default-section h4 {
  color: #fff;
  font-family: "Molot";
  font-size: 22px;
  position: relative;
  padding-bottom: 10px;
}

.default-section img.feature-img {
  border-radius: 30px;
}

.feature-list li {
  color: #fff;
}
/* 
.default-section .title-page.payment-title h2:after {
  left: 220px;
  width: calc(100% - 220px);
}

.default-section .title-page.responsible-title h2:after {
  left: 450px;
  width: calc(100% - 450px);
}

.default-section .title-page.security-title h2:after {
  left: 480px;
  width: calc(100% - 480px);
}

.default-section .title-page.vision-title h2:after {
  left: 250px;
  width: calc(100% - 250px);
}


.default-section .title-page.team-title h2:after {
  left: 250px;
  width: calc(100% - 250px);
} */

.slick-list,
.slick-slider,
.slick-track {
  text-align: center;
}

.footer-one p {
  text-align: left !important;
  font-family: "Roboto-Light" !important;
}

/***********  Modal ******************/

.bet-popup .modal-header {
  padding: 15px;
  border-bottom: 1px solid#161b29;
}

.bet-popup .modal-title {
  color: #fff;
  font-family: "Molot";
  letter-spacing: 0.5px;
}

.bet-popup .form-label {
  color: #fff;
}

.bet-popup .modal-content {
  background-color: #0d111e;
  border: 1px solid rgb(5 5 29);
}

.bet-popup .modal-body select.form-control {
  border: 1px solid #333;
  border-radius: 5px;
  background: transparent;
}

.bet-popup .m-2 .btn {
  width: 49%;
  padding: 10px;
  font-size: 13px;
  margin-top: 10px;
  border-radius: 4px !important ;
}

select.form-control option {
  background-color: #0d111e !important;
}

select.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.add-money img {
  width: 22px;
  margin-left: 5px;
  cursor: pointer;
}

/**************** Input & Tabale Flare Effect **********************/

.form-control {
  border-image-slice: 1 !important;
  border-width: 1px !important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem #3bbdf5 !important;
  border-radius: 0 !important;
}

.home-table .table > tbody > tr::before {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.home-table .table > thead > tr {
  border: none;
}

.home-table .table > tbody > tr::after {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.home-table .table > tbody > tr,
.home-table .table > thead > tr {
  border: 1px solid#3d4253;
  display: grid;
  grid-template-columns:
    calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7)
    calc(100% / 7);
  position: relative;
}

.home-table .table > thead > tr {
  border: none;
}

.home-table .table > tbody > tr {
  margin-bottom: 20px;
  border-radius: 2px;
}

.home-table .table > tbody > tr > td {
  word-break: break-all;
}

.create-tounament .home-table .table > tbody > tr,
.create-tounament .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3) !important ;
}

.profile .home-table .table > tbody > tr,
.profile .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6);
}

.transactionpage .home-table .table > tbody > tr,
.transactionpage .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6) calc(100% / 6);
}

.notificationpage .home-table .table > tbody > tr,
.notificationpage .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
}

.finished-tournament .home-table .table > tbody > tr,
.finished-tournament .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5);
}

.accordion__heading::before {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.accordion__heading {
  position: relative;
}

.accordion__heading::after {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(90deg, rgba(2, 7, 18, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(2, 7, 18, 0) 100%);
  height: 1px;
  width: 50%;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.faqs .accordion__button {
  border: 1px solid#3d4253;
}

select.form-control {
  background: transparent !important;
}

.game-list-view .table-gamer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.game-list-view .table-gamer span.gamer-vs {
  width: 20%;
}

.game-list-view .table-gamer span.gamer-name {
  width: 40%;
}

.game-list-view .table-gamer span.gamer-name:first-child {
  text-align: right;
}

.game-list-view .table-gamer span.gamer-name:last-child {
  text-align: left;
}

.mobile-app-link {
  position: absolute;
  background: #080d1cbf;
  padding: 10px 10px;
  text-align: center;
  top: 20px;
  right: 20px;
  border: 1px solid #6f6969;
  border-radius: 3px;
  max-width: 170px;
  opacity: 0;
  transition: 0.5s;
  z-index: 999;
}
.mobile-app-link1 {
  position: absolute;
  background: #080d1cbf;
  padding: 10px 10px;
  text-align: center;
  top: 20px;
  right: -120px !important;
  border: 1px solid #6f6969;
  border-radius: 3px;
  max-width: 170px;
  opacity: 0;
  transition: 0.5s;
  z-index: 999;
}

.game-action .newtournament-action:hover .mobile-app-link {
  opacity: 1;
}

.game-action .newtournament:hover .mobile-app-link {
  opacity: 1;
}
.game-action .newduel-action:hover .mobile-app-link {
  opacity: 1;
}
.game-img .trynew:hover .mobile-app-link {
  opacity: 1;
}
.game-action.mobile-app-tile .newtournament:hover {
  width: 50px;
}
.game-action.mobile-app-tile .newtournament-action:hover {
  width: 50px;
}
.game-action.mobile-app-tile .newduel-action:hover {
  width: 50px;
}
.game-img .trynew:hover {
  width: 50px;
}

.mobile-app-link span {
  color: #fff;
  font-size: 10px;
  padding: 0 !important;
}

.mobile-app-img img {
  width: 132px !important;
}

.mobile-app-img {
  padding-top: 5px;
}

.webgl-content .footer .webgl-logo {
  display: none !important;
}

.signup .rigister-tab .dropdown-menu,
.dod-popup .editprofile .dropdown-menu {
  position: absolute !important;
  background: #050c1b;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 49px !important;
}

.loginBtn div,
.loginBtn button {
  background: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
}

.loginBtn--facebook .kep-login-facebook {
  text-transform: capitalize !important;
  font-weight: 100;
  box-shadow: none !important;
  padding: 11px 20px;
  font-size: 14px;
  border: none;
}

.loginBtn--facebook .kep-login-facebook:focus,
.loginBtn button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.notification {
  box-sizing: border-box;
  padding: 15px 20px 15px 58px !important;
  border-radius: 4px !important;
  color: #fff;
  background-color: #ccc;
  box-shadow: none !important;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 50px !important;
  text-align: right;
  border: 1px solid #3bbdf59e !important;
}

/* multiplayer */
.game-action.mobile-app-tile .multiplayer-action:hover {
  width: 50px !important;
}
.game-action .multiplayer-action:hover .mobile-app-link {
  opacity: 1;
}
.game-action.mobile-app-tile .multiplayer-action {
  overflow: inherit;
}
/* home tile slider */
.home-tile-slider .slick-dots {
  position: absolute;
  bottom: 15px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.home-tile-section .col-md-6.tile-slider-one {
  padding-right: 0px;
}
.home-tile-slider .slick-dots li.slick-active button,
.home-tile-slider .slick-dots li.slick-active button:hover,
.home-tile-slider .slick-dots li.slick-active button:focus {
  background-color: white !important;
  border: 1px solid white;
}
.home-tile-slider .slick-dots li button {
  background-color: transparent !important;
  border: 1px solid white;
}

.home-tile-slider .slick-dots li button:before {
  display: none;
}
.home-tile-section .col-md-6 {
  padding-left: 12px;
}

.home .how-tournament-section {
  position: relative;
  /* padding: 0px; */
  padding-bottom: 0px;
}

.duel-bg-slide {
  background: #080d1c url("./assets/images/home/how-tournament-section.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 100px 0px;
}

.tournament-bg-slide {
  background: #080d1c url("./assets/images/home/how-tournament-section1.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 100px 0px;
}

/* .how-tournament-section::before {
  content: "";
  background: #03091b73;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
} */

.align-tournament {
  width: 100%;
}

.home .day-of-duel-section {
  background: url("./assets/images/home/day-of-duel-section.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
}

/***************** BG HOME CSS NEW ***************/

@media screen and (max-width: 3840px) {
  .home .home-banner {
    background: url(./assets/images/home-images/3840/1.png);
    height: 2695px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets/images/home-images/3840/2.png);
    height: 1699px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets/images/home-images/3840/4.png);
    min-height: auto;
    height: 1900px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/3840/3-duel.png);
    height: 1736px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/3840/3-tournament.png);
    min-height: auto;
    height: 1377px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 2560px) {
  .home .home-banner {
    background: url(./assets/images/home-images/2560/1.png);
    height: 1797px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets/images/home-images/2560/2.png);
    height: 1133px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets/images/home-images/2560/4.png);
    min-height: auto;
    height: 1265x;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/2560/3-duel.png);
    height: 1157px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/2560/3-tournament.png);
    min-height: auto;
    height: 918px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 2048px) {
  .home .home-banner {
    background: url(./assets/images/home-images/2048/1.png);
    height: 1437px;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: auto;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/2048/2.png);
    height: 906px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/2048/4.png);
    min-height: auto;
    height: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/2048/3-duel.png);
    height: 926px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/2048/3-tournament.png);
    min-height: auto;
    height: 734px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1920px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1920/1.png);
    height: 1348px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1920/2.png);
    height: 850px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1920/4.png);
    min-height: auto;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1920/3-duel.png);
    height: 868px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1920/3-tournament.png);
    min-height: auto;
    height: 689px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 1680px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1680/1.png);
    height: 1179px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1680/2.png);
    height: 743px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1680/4.png);
    min-height: auto;
    height: 850px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1680/3-duel.png);
    height: 760px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1680/3-tournament.png);
    min-height: auto;
    height: 602px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1600px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1600/1.png);
    height: 1123px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1600/2.png);
    height: 708px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1600/4.png);
    min-height: auto;
    height: 815px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1600/3-duel.png);
    height: 723px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1600/3-tournament.png);
    min-height: auto;
    height: 574px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1536px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1536/1.png);
    height: 1078px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1536/2.png);
    height: 680px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1536/4.png);
    min-height: auto;
    height: 689px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1536/3-duel.png);
    height: 694px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1536/3-tournament.png);
    min-height: auto;
    height: 551px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1440px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1440/1.png);
    height: 1011px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1440/2.png);
    height: 637px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1440/4.png);
    min-height: auto;
    height: 624px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1440/3-duel.png);
    height: 651px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1440/3-tournament.png);
    min-height: auto;
    height: 516px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1366px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1366/1.png);
    height: 959px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1366/2.png);
    height: 604px;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: auto;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1366/4.png);
    min-height: auto;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1366/3-duel.png);
    height: 618px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1366/3-tournament.png);
    min-height: auto;
    height: 490px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}

@media screen and (max-width: 1280px) {
  .home .home-banner {
    background: url(./assets/images/home-images/1280/1.png);
    height: 898px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home .gamepad-bg {
    background: url(./assets//images/home-images/1280/2.png);
    height: 566px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .home .day-of-duel-section {
    background: url(./assets//images/home-images/1280/4.png);
    min-height: auto;
    height: 532px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .duel-bg-slide {
    background: url(./assets/images/home-images/1280/3-duel.png);
    height: 579px;
    min-height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .tournament-bg-slide {
    background: url(./assets//images/home-images/1280/3-tournament.png);
    min-height: auto;
    height: 459px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  div#populargame {
    margin-top: 50px !important;
  }
}

/* .gamepad-bg {
  margin-top: -165px;
} */

.home-tile-section {
  margin-top: -170px;
}
.gamepad-bg {
  padding: 15px 0px;
}
div#populargame {
  margin-top: 150px;
}
.home-banner {
  margin-top: -50px;
}
/* .day-of-duel-section {
  margin-top: -100px;
} */
