.chat-log {
  overflow: auto;
  width: 100%;
  margin: 0px auto;
  height: 200px;
  padding: 0px 0px;
  margin-bottom: 20px;
}

.chat-log__item {
  background: transparent;
  padding: 10px;
  margin: 0 auto 5px;
  max-width: 80%;
  float: left;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  clear: both;
  text-align: left;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
}

.chat-log__item.chat-log__item--own {
  float: right;
  background: transparent;
  text-align: left;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
}

.chat-user {
  margin-right: 10px;
  width: 32px;
}

.chat-user img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  object-fit: cover;
}

.chat-form {
  background: transparent;
  padding: 5px 5px;
  bottom: 0;
  width: 100%;
  margin: 0px auto;
}

.chat-log__author {
  margin: 0 auto 0.5em;
  font-size: 14px;
  font-weight: bold;
}
.chat-box {
  background: #02091d;
  padding: 20px 10px;
  max-width: 699px;
  margin: 15px auto;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  overflow: hidden;
  padding-right: 25px;
  box-shadow: 0px 0px 15px 0px #ffffff9c;
  padding: 20px 0px;
}

.btn-block:hover {
  background-color: #205388;
  border-color: #205388;
}

.btn-block {
  background-color: #8f1c1c;
  color: #fff !important;
  border-color: #8f1c1c;
}
.chat-log::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #11192f;
}

.chat-log::-webkit-scrollbar {
  width: 7px;
  background-color: #11192f;
}

.chat-log::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #040e29;
}

ul.chat-log {
  list-style: none;
}

.chat-form .form-control {
  background: transparent;
  padding-right: 40px;
  border: 2px solid #087088;
  color: #fff;
  font-size: 14px;
}

.chat-form-type {
  /* display: flex; */
  align-items: stretch;
  position: relative;
}

.chat-form-type form {
  position: relative;
}

.btn-type {
  background: transparent;
  color: #fff;
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0px 15px;
  z-index: 9999;
  top: 0;
}

.btn-type:focus {
  outline: none;
}

.dod-popup.chatbox-popup .modal-header .close {
  color: #087088;
}

.chat-form .form-control::placeholder {
  color: #fff;
}

.btn-type i {
  color: #087088;
  font-size: 22px;
}

.chat-form .form-control:focus {
  box-shadow: none;
}

.chat-log__message {
  color: #fff;
  width: calc(100% - 42px);
}

/*************New **************/

.chatbox-overlay.show {
  opacity: 0 !important;
}

.chatbox-popup .modal-body {
  max-width: 300px;
  padding: 0;
}
.chat-date {
  color: #d1cbcb;
  text-align: center;
  font-size: 12px;
}
.chatting {
  display: flow-root;
}

.chat-log__message span {
  font-size: 10px;
}

.chat-log__message p {
  margin-bottom: 0px;
}
